import {
  Box,
  Button,
  Toast,
  Collapse,
  Text,
  useDisclosure,
  useToast,
  Tr,
  Td,
  HStack,
  VStack,
  Container,
  List,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import { setIsAlarmModalOpen } from "../slices/isAlarmModalOpenSlice";

import { useEffect, useState } from "react";
import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import alarm from "../../images/alarm.png";
import alarmon from "../../images/frontalarmon.png";
const AlarmButtonComponent = ({ chat, user }) => {
  const dispatch = useDispatch();
  const [alarms, setAlarms] = useState([]);
  const [loading, setLoading] = useState(true);
  const getAlarms = async () => {
    console.log("====================getAlarm");
    console.log(chat._id);
    console.log(user._id);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/alarm/get/${chat?._id}`,
          {
            cid: chat?._id,
            uid: user._id,
          },
          config
        )
        .then((res) => {
          console.log("==========================");
          console.log(res);
          console.log("==================");
          setAlarms(res.data);
          setLoading(false);
        });
    } catch (error) {
      console.log(error.data);
    }
  };

  // /api/alarm/get/:cid
  const handleAlarm = () => {
    dispatch(setSelectedTask(chat));
    dispatch(setIsAlarmModalOpen(true));
    //  dispatch(setSelectedAlarm(chat.alarm));
  };

  useEffect(() => {
    getAlarms();
    setLoading(true);
  }, [chat]);

  return (
    <Button
      isLoading={loading}
      width={"40px"}
      onClick={handleAlarm}
      size={"xs"}
    >
      {alarms.length > 0 ? (
        <img
          className="alarmimg"
          width={"10px"}
          height={"10px"}
          alt="set alarm"
          src={alarmon}
        />
      ) : (
        <img
          className="alarmimg"
          width={"10px"}
          height={"10px"}
          src={alarm}
          alt="set alarm"
        />
      )}
    </Button>
  );
};

export default AlarmButtonComponent;
