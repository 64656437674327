import {
  Box,
  Button,
  Toast,
  Collapse,
  Text,
  useDisclosure,
  useToast,
  Tr,
  Td,
  HStack,
  VStack,
  Container,
  List,
  Spinner,
} from "@chakra-ui/react";
import {
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { getSender } from "../../config/ChatLogics";
import { setIsChatDrawerOpen } from "../isChatDrawerOpenSlice";
import { useDispatch, useSelector } from "react-redux";
import { ContextScope } from "../../Context/ContextProvider";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import SubtasksList from "../subtasks/SubtasksList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import TeamList from "./TeamList";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import { setIsAddTaskModalOpen } from "../slices/isAddTaskModalOpenSlice";
import { setModalMode } from "../slices/modalModeSlice";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import GroupChatModal from "../miscellaneous/GroupChatModal";
import DoneTaskComponent from "./DoneTaskComponent";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { format } from "date-fns";
import { setDrawerMode } from "../slices/drawerModeSlice";
import Subtask from "../subtasks/Subtask";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { setSelectedAlarm } from "../slices/selectedAlarmSlice";

import AlarmButtonComponent from "../alarm/AlarmButtonComponent";
import { setIsGroupChatModalOpen } from "../slices/isGroupChatModalOpenSlice";
import AcceptTask from "./AcceptTask";
import { setIsEventModalOpen } from "../slices/isEventModalOpenSlice";
import CommentComponent from "./CommentComponent";
import CommentsComponent from "./CommentsComponent";
import SubtasksButtonComponent from "./SubtasksButtonComponent";
import SubtasksDatesAlertComponent from "./SubtasksDatesAlertComponent";
import ChatAttachments from "../attachments/ChatAttachments";

const TaskComponent = ({ chat }) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuper, setIsSuper] = useState(false);
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [subtasks, setSubtasks] = useState([]);
  const [content, setContent] = useState(
    <Tr>
      <Td>
        <Spinner />
      </Td>
    </Tr>
  );
  const [update, setUpdate] = useState(false);
  const [showCollapse, setShowCollapse] = useState(true);
  const [descriptionCollapse, setDescriptionCollapse] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [daysLeftColor, setDaysLeftColor] = useState("gray.800");
  const [daysLeftBG, setDaysLeftBG] = useState("gray.300");
  const [show, setShow] = useState(false);
  const [showDoneClass, setShowDoneClass] = useState("");
  const [done, setDone] = useState(false);
  const { onOpen, isOpen, onToggle } = useDisclosure();
  const user = useSelector((state) => state.userInfo.userInfo);
  const showDone = useSelector((state) => state.showDone.showDone);
  const {
    alert,
    setAlert,
    selectedChat,
    setSelectedChat,
    setSelectedPrivateChat,
    chats,
    setChats,
  } = ContextScope();
  const dispatch = useDispatch();
  const isAddTaskModalOpen = useSelector(
    (state) => state.isAddTaskModalOpen.isAddTaskModalOpen
  );

  const isChatDrawerOpen = useSelector(
    (state) => state.isChatDrawerOpen.isChatDrawerOpen
  );
  const openDrawer = (chat) => {
    // // // console.log(isChatDrawerOpen);
    setSelectedChat(chat);
    dispatch(setIsChatDrawerOpen(true));
  };

  const openModal = () => {
    dispatch(setIsUpdateChatModalOpen(true));
    setSelectedChat(chat);
  };
  useEffect(() => {
    if (chat?.groupAdmin) {
      if (
        chat?.groupAdmin?.some((e) => e._id === user?._id) ||
        chat?.groupAdmin?.some((e) => e === user?._id)
      ) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
    if (chat?.groupSuper?.length > 0) {
      if (
        chat?.groupSuper?.some((e) => e?._id === user?._id) ||
        chat?.groupSuper?.some((e) => e === user?._id)
      ) {
        setIsSuper(true);
      } else {
        setIsSuper(false);
      }
    } else {
      if (
        chat?.groupSuper?._id === user?._id ||
        chat?.groupSuper === user?._id
      ) {
        setIsSuper(true);
      } else {
        setIsSuper(false);
      }
    }
    if (timeLeft < 10 && timeLeft > 0) {
      setDaysLeftBG("#ff9326");
      setDaysLeftColor("orange");
    } else if (timeLeft <= 0) {
      setDaysLeftBG("red");
      setDaysLeftColor("red");
    } else {
      setDaysLeftBG("gray.300");
      setDaysLeftColor("gray.800");
    }
    if (chat.done === true) {
      if (showDone === false) {
        if (isOpen) {
          onToggle();
        }
        setShowDoneClass("subtaskTr");
        setIsOpenCollapse(false);
        setShowCollapse(false);
      }
      setDaysLeftBG("#9fba3c");
      setDaysLeftColor("white");
    } else {
      setShowCollapse(true);
    }
    setDone(chat.done);
  }, [chat]);
  useEffect(() => {
    subtasks.map((s) => {
      if (s.newMessages > 0 && !update) {
        setUpdate(true);
        // setNewMessages("red");
      }
    });
    setContent(
      subtasks.map((subtask, index) => (
        <Tr margin={"0px"} p={"0px"}>
          <Subtask
            chatDone={chat.done}
            showCollapse={showCollapse}
            index={index}
            last={subtasks.length - 1}
            className={showDoneClass}
            p={"0px"}
            opened={isOpen}
            isOpen={isOpenCollapse}
            key={subtask._id}
            subtask={subtask}
          />
        </Tr>
      ))
    );
  }, [subtasks]);
  const setMode = () => {
    // // console.log("zzz");
    dispatch(setIsGroupChatModalOpen(true));
    dispatch(setSelectedTask(chat));
    setSelectedChat(chat);
    dispatch(setModalMode("sub"));
  };
  const handleSubClick = async (target) => {
    setSelectedPrivateChat(null);
    dispatch(setSelectedTask(chat));
    dispatch(setDrawerMode(target));
    openDrawer(chat);
    if (target !== "chat") {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        };
        const { data } = await axios.put(
          `${mainPath}/api/chat/read`,
          {
            chatId: chat?._id,
          },
          config
        );

        dispatch(setFetchAgain(!fetchAgain));
      } catch (error) {
        // toast({
        //   title: error.message,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        //   position: "top-left",
        // });
      }
    }
  };

  const dateTo = format(new Date(chat?.dateTo.toString()), "dd/MM/yyyy");
  const timeLeft = Math.round(
    (new Date(chat.dateTo) - Date.now()) / (1000 * 60 * 60 * 24)
  );

  useEffect(() => {
    if (!showDone) {
      if (chat.done) {
        if (isOpen) {
          onToggle();
        }
        setShowCollapse(false);
        setShowDoneClass("subtaskTr");
        setIsOpenCollapse(false);
      }
    } else {
      if (chat.done) {
        setShowCollapse(true);
        setShowDoneClass("");
        if (isOpen) setIsOpenCollapse(true);
        else setIsOpenCollapse(false);
      }
    }
  }, [showDone]);

  useEffect(() => {
    setIsOpenCollapse(isOpen);
    if (showCollapse) setIsOpenCollapse(isOpen);
    else setIsOpenCollapse(false);
  }, [isOpen]);
  // const handleAlarm = () => {
  //   dispatch(setSelectedTask(chat));
  //   dispatch(setIsAlarmModalOpen(true));
  //   dispatch(setSelectedAlarm(chat.alarm));
  // };
  const handleEvent = () => {
    dispatch(setIsEventModalOpen(true));
    dispatch(setSelectedTask(chat));
    // dispatch(setSelectedAlarm(chat.alarm));
  };
  let invitation = [];
  if (chat?.suggestedSuper?.some((e) => e === user._id)) {
    invitation.push("administrowania");
  }
  if (chat?.suggestedSuper === user?._id.toString()) {
    invitation.push("nadzorowania");
  }
  if (chat?.suggestedSuper?.some((e) => e === user._id)) {
    invitation.push("realizacji");
  }
  return (
    <>
      <Tr p={0}>
        <Td className={showDoneClass} borderWidth={"1px 0px 0px 0px"}>
          <Collapse in={showCollapse} animateOpacity>
            <HStack>
              <SubtasksButtonComponent
                user={user}
                chat={chat}
                setSelectedChat={setSelectedChat}
                onToggle={onToggle}
                subtasks={subtasks}
                setSubtasks={setSubtasks}
                setLoading={setLoading}
              />
              {!chat.done && (
                <Button
                  onClick={setMode}
                  borderRadius={6}
                  bg={"blue.200"}
                  color={"gray.100"}
                  size={"xs"}
                  display="flex"
                  fontSize={{ base: "12px" }}
                  colorScheme="linkedin"
                >
                  <FontAwesomeIcon color="white" icon={faPlus} />
                </Button>
              )}
            </HStack>
          </Collapse>
        </Td>
        <Td
          w={"100%"}
          className={showDoneClass}
          borderWidth={"1px 0px 0px 0px"}
        >
          <Collapse in={showCollapse} animateOpacity>
            {chat?.suggestedAdmin?.some((e) => e === user._id) ||
            chat?.suggestedSuper?.some((e) => e === user._id) ||
            chat?.suggestedSuper === user._id ||
            chat?.suggestedUsers?.some((e) => e === user._id) ? (
              <Box
                border={"1px solid #eeeeee"}
                p={"10px 15px"}
                borderRadius={4}
              >
                <p className="small">
                  {chat.groupAdmin[0].name} zaprosił cię do:
                  <UnorderedList p={"5px"}>
                    {invitation?.map((i) => (
                      <ListItem>{i}</ListItem>
                    ))}
                  </UnorderedList>
                </p>
                <AcceptTask user={user} chat={chat} />
              </Box>
            ) : (
              <Box borderRadius={"9px"} p={"5px 5px"} border={"1px solid #fff"}>
                <HStack>
                  <>
                    {isAdmin ? (
                      <DoneTaskComponent mode="task" chat={chat} />
                    ) : (
                      <Button
                        isDisabled={true}
                        // border={chat.done ? "2px solid #9fba3c" : "none"}
                        bg={chat.done ? "#9fba3c" : "transparent"}
                        size={"xs"}
                        display={{ base: "flex" }}
                      >
                        {chat.done ? (
                          <FontAwesomeIcon color="white" icon={faCheck} />
                        ) : (
                          <FontAwesomeIcon color="#aaaaaa" icon={faCheck} />
                        )}
                      </Button>
                    )}
                    <Button
                      isDisabled={!isAdmin && !isSuper}
                      bg={"transparent"}
                      display={{ base: "flex" }}
                      size={"xs"}
                      onClick={() => {
                        dispatch(setModalMode("edit"));
                        openModal();
                      }}
                    >
                      <FontAwesomeIcon color="#aaaaaa" icon={faGear} />
                    </Button>
                  </>
                  <Button
                    // bg={"#d5e9f0"}
                    size={"xs"}
                    color="orange.600"
                    bg={"transparent"}
                    onClick={() => handleSubClick("notes")}
                  >
                    <FontAwesomeIcon color="#de924b" icon={faPenToSquare} />
                    {/* <HStack>
                <span padding={"0px 5px"} className="small lightgray">
                  {chat.allMessages}
                </span>
                {chat.newMessages > 0 && (
                  <text padding={"0px 5px"} className="small red">
                    {chat.newMessages}
                  </text>
                )}
              </HStack> */}
                    {/* {chat.groupAdmin._id} : {chat.groupSuper._id} : {user._id} */}
                  </Button>
                  <CommentComponent
                    setSelectedPrivateChat={setSelectedPrivateChat}
                    setSelectedChat={setSelectedChat}
                    rawChat={chat}
                  />
                  <CommentsComponent
                    setSelectedPrivateChat={setSelectedPrivateChat}
                    setSelectedChat={setSelectedChat}
                    rawChat={chat}
                  />
                  <ChatAttachments
                    setSelectedPrivateChat={setSelectedPrivateChat}
                    setSelectedChat={setSelectedChat}
                    rawChat={chat}
                  />
                </HStack>
              </Box>
            )}
            <HStack marginTop={"5px"}>
              <Text
                className="smaller noMargin"
                verticalAlign={"top"}
                margin={"0"}
                padding={"0"}
                color={
                  chat.groupAdmin.some((e) => e === user._id)
                    ? "blue.600"
                    : "gray.400"
                }
              >
                {format(new Date(chat?.createdAt.toString()), "dd/MM/yyyy")}
              </Text>
              <Text
                className="smaller noMargin"
                h={"10%"}
                verticalAlign={"top"}
                margin={"0"}
                padding={"0"}
                color={
                  chat.groupAdmin.some((e) => e === user._id)
                    ? "blue.600"
                    : "gray.400"
                }
              >
                {chat.groupAdmin[0].name}
              </Text>
            </HStack>
            <HStack
              onClick={() => handleSubClick("notes")}
              // onClick={() => setDescriptionCollapse(!descriptionCollapse)}
            >
              {/* {chat.description && (
                <p className={showDoneClass}>
                  <Button
                    p={"1px 6px 1px 6px"}
                    color={"white"}
                    size={"xs"}
                    onClick={() => handleSubClick("notes")}
                    // onClick={() => setDescriptionCollapse(!descriptionCollapse)}
                  >
                    <HStack> 
                      <FontAwesomeIcon
                        color="#90cdf4"
                        size="lg"
                        icon={faEllipsis}
                      />
                    </HStack>
                  </Button>
                </p>
              )} */}
              <Text
                className={chat.done ? "doneTitle" : "mainFontColor title hand"}
              >
                <p className={chat.description && "link"}>
                  {!chat.isGroupChat
                    ? getSender(user, chat.users)
                    : chat.chatName}
                </p>
              </Text>
            </HStack>
          </Collapse>
        </Td>
        {/* <Td className={showDoneClass} borderWidth={"1px 0px 0px 0px"}></Td> */}
        <Td className={showDoneClass} borderWidth={"1px 0px 0px 0px"}>
          <Collapse in={showCollapse} animateOpacity>
            <HStack>
              <VStack align={"right"} marginRight={"5px"}>
                <TeamList team={chat.users} />
              </VStack>
              <VStack align={"start"}>
                <p className="small">{chat.groupSuper?.name}</p>
                <p className="small gray">{chat.groupSuper?.email}</p>
              </VStack>
            </HStack>
          </Collapse>
        </Td>
        {/* <Td className={showDoneClass} borderWidth={"1px 0px 0px 0px"}>
          <Collapse in={showCollapse} animateOpacity>
            <TeamList team={chat.users} />
          </Collapse>
        </Td> */}
        <Td borderWidth={"1px 0px 0px 0px"} className={showDoneClass}>
          <Collapse in={showCollapse} animateOpacity>
            <HStack className="small gray">
              <Text>{dateTo}</Text>
              <Box
                className="hand"
                onClick={() => {
                  dispatch(setModalMode("date"));
                  openModal();
                }}
                alignItems={"center"}
                alignSelf={"center"}
                textAlign={"center"}
                alignContent={"center"}
                minWidth={"70px"}
                borderRadius={6}
                color={daysLeftColor}
                borderWidth={"2px"}
                borderColor={daysLeftBG}
                p={"3px 7px 2px 5px"}
              >
                {chat.done === false ? (
                  <>{`${timeLeft} dni`}</>
                ) : (
                  <Box p={"0px 4px 0px 4px"}>
                    <FontAwesomeIcon color="#9fba3c" icon={faCheck} />
                  </Box>
                )}
              </Box>
              <SubtasksDatesAlertComponent user={user} cid={chat._id} />
              <AlarmButtonComponent chat={chat} user={user} />
              {/* <Button width={"40px"} onClick={handleAlarm} size={"xs"}>
                {chat?.alarm?.length > 0 ? (
                  <img alt="set alarm" src={alarmon} />
                ) : (
                  <img
                    className="alarmimg"
                    width={"10px"}
                    height={"10px"}
                    src={alarm}
                    alt="set alarm"
                  />
                )}
              </Button> */}
              <Button
                onClick={handleEvent}
                title="historia"
                color="blue.300"
                width={"40px"}
                size={"xs"}
              >
                <FontAwesomeIcon size="md" icon={faCalendarDays} />
              </Button>
              <Button
                isDisabled={true}
                title="historia"
                color="gray.400"
                width={"40px"}
                size={"xs"}
              >
                <FontAwesomeIcon size="md" icon={faCodeBranch} />
              </Button>
            </HStack>
          </Collapse>
        </Td>
        <Td className={showDoneClass} borderWidth={"1px 0px 0px 0px"}>
          <Collapse in={showCollapse} animateOpacity></Collapse>
        </Td>
      </Tr>
      {content}
      <Tr>
        <Collapse in={isOpen} animateOpacity>
          <Td className="subtaskTrBreak" colSpan={5}>
            {" "}
          </Td>
        </Collapse>
      </Tr>
    </>
  );
};

export default TaskComponent;
